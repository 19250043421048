import React, {useState, useEffect} from "react";
import {Link, Trans, useTranslation, useI18next, I18nextContext} from 'gatsby-plugin-react-i18next';
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql} from 'gatsby';
import Settings from '../../constants/company/settings';
import ContinentSettings from '../../constants/continents/geo-continents';
import hubspotSettings from "../../constants/company/settings";
import {globalHistory} from "@reach/router";
import ContactCards from '../../components/contact/contact-cards';
import {StaticImage} from "gatsby-plugin-image";

const IndexPage = () => {
  let isBrowser = typeof window !== "undefined";
  const {t} = useTranslation();
  const {changeLanguage} = useI18next();
  const context = React.useContext(I18nextContext);
  const [isSupport, setIsSupport] = useState(globalHistory.location.pathname.indexOf('support') > -1);
  const firstNameInputRef = React.useRef(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    phoneNumber: '',
    state: '',
    country: '',
    message: '',
    formSelected: 'sales',
    department: hubspotSettings.SALES_EMAIL
  });

  const handleCountryChange = (data) => {
    setFormData(data);
    if(data.country.toUpperCase() === 'US') {
      setIsCountryUsa(true);
    } else {
      setFormData(prevState => ({
        ...prevState,
        state: ''
      }));
      setIsCountryUsa(false);

    }
  }
  const [sourceDrillDown, setSourceDrillDown] = useState('');
  const[isCountryUsa, setIsCountryUsa] = useState(false);
  const isFormValid = formData.email != null && formData.email.trim().length > 0;
  const [whichStep, setWhichStep] = useState(1);
  const [emailIsClicked, setEmailIsClicked] = useState(false);

  const submit = (e) => {
    e.preventDefault();

    const postSubmit = hubspotSettings.CONTACT_FORM_ENDPOINT;
    // const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
    // const pageUri = isBrowser ? window.location.href : null;
    // const pageName = isBrowser ? document.title : null;
    const body = {

      fields: [
        {
          name: 'firstname',
          value: formData.firstName,
        },
        {
          name: 'lastname',
          value: formData.lastName,
        },
        {
          name: 'company',
          value: formData.companyName,
        },
        {
          name: 'email',
          value: formData.email,
        },
        {
          name: 'phone',
          value: formData.phoneNumber,
        },
        {
          name: 'country_test',
          value: formData.country,
        },
        {
          name: 'state',
          value: formData.state,
        },
        {
          name: 'department',
          value: formData.department,
        },
        {
          name: 'message',
          value: formData.message,
        },
        {
          name: 'leadsource',
          value: 'Website',
        },
        {
          name: 'source_drill_down',
          value: sourceDrillDown
        }
      ]
    }

    fetch(postSubmit, {
      method: 'post',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      },
    })
        // .then(res => res.json())
        .then((res) => {

          handleSubmitSuccess();
        })
        .catch(err => {
          alert(err)
        })
  };

  let handleSubmitSuccess = () => {
    if (context.language !== 'en') {
      window.location.href = '/' + context.language + '/contact/thank-you/'
    } else {
      window.location.href = '/contact/thank-you/'
    }
  }

  function handleStepClick() {
    const url = new URL(window.location);
    url.searchParams.set('help', 'purchaseLocks');
    window.history.pushState({}, '', url);
    setWhichStep(2);
  }

  function handleEmailClicked() {
    const url = new URL(window.location);
    url.searchParams.set('choice', 'email');
    window.history.replaceState({}, '', url);
    setEmailIsClicked(!emailIsClicked);
    setWhichStep(3);
    document.getElementById('firstName').focus();
  }

  function handleSalesChatClick() {
    window.history.pushState({}, 'bot', '?bot=sales');
    window.HubSpotConversations.clear({resetWidget: true});
  }

  const [infoCurrent, setInfoCurrent] = useState({
    header: t('digilock_americas'),
    address: t('digilock_americas_address'),
    phone: Settings.PHONE_SALES_AMERICAS,
    callSales: isSupport ? t('call_support') : t('call_sales'),
    emailSales: t('email_sales'),
    email: Settings.SALES_EMAIL,
    liveChat: t('live_chat'),
    liveChatCopy: t('live_chat_copy'),
    tollFree: t('toll_free'),
    tollFreeNumber: Settings.PHONE_SALES_TOLL_FREE_AMERICAS,
    phoneNumber: Settings.PHONE_SALES_TOLL_FREE_AMERICAS
  });
  const [continent, setContinent] = useState(isBrowser ? sessionStorage.getItem(ContinentSettings.SESSION_CONTINENT) : 'North America');

  function handleInfoCurrentChange() {
    switch (continent) {
      case ContinentSettings.EUROPE:
        setInfoCurrent(prevState => ({
          ...prevState,
          ['header']: t('digilock_europe'),
          ['address']: t('digilock_europe_address'),
          ['phone']: Settings.PHONE_SALES_EUROPE,
          ['email']: Settings.SALES_EMAIL_EUROPE
        }));
        break;
      case ContinentSettings.ASIA:
        setInfoCurrent(prevState => ({
          ...prevState,
          ['header']: t('digilock_asia'),
          ['address']: t('digilock_asia_address'),
          ['phone']: Settings.PHONE_SALES_ASIA,
          ['email']: Settings.SALES_EMAIL_ASIA,
          ['tollFree']: '',
          ['tollFreeNumber']: ''
        }));
        break;
      default:
        //code
    }
  }

  function gtag_report_conversion_asia_submit(url) {
    let callback = function () {
      if (typeof (url) != 'undefined') {
        // window.location = url;
      }
    };
    window.gtag('event', 'conversion', {
      'send_to': 'AW-829366430/6pEtCLqd4poYEJ7BvIsD',
      'event_callback': callback
    });
    return false;
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    window.onpopstate = function () {
      if (!window.location.pathname.indexOf('?') > -1) {
        setWhichStep(1);
        setEmailIsClicked(false);
      }
    }

    if (searchParams.has("help")) {
      setWhichStep(3);
      setEmailIsClicked(true);
    }

    if (isBrowser) {
      handleInfoCurrentChange();
    }
    firstNameInputRef.current.focus();
    if (searchParams.has("mkc")) {
      setSourceDrillDown('Global Healthcare 2023');
    }

    let form = document.querySelector('form');
    let overlay = document.getElementsByClassName('nav-overlay')[0];

    form.addEventListener('submit', function () {
      this.querySelector('input[type="submit"]')
          .setAttribute('aria-disabled', 'true');
      overlay.classList.add('show');
    }, false);

  }, []);

  return (
      <Layout>
        <Seo
            title={t('contact_title')}
            description={t('des_contact')}
        />
        <div className="contact">
          <section className="hero">
            <div className="container">
              <h1 dangerouslySetInnerHTML={{__html: whichStep === 1 ? t('how_can_we_help') : t('contact_us')}}/>
              {
                whichStep === 2 ? (
                    <p><Trans>select_preferred_method</Trans></p>
                ) : null
              }
            </div>
          </section>
          <section className="contact-info">
            <div className="container">
              <div className="sales-form">
                <ContactCards
                    info={infoCurrent}
                    handleEmail={handleEmailClicked}
                    emailClicked={emailIsClicked}
                    handleChatClick={handleSalesChatClick}
                    handleStepClick={handleStepClick}
                    whichStep={whichStep}
                />
                <form id={'salesForm'}
                      action="#"
                      className={emailIsClicked ? 'sales-contact-form main show' : 'sales-contact-form main'}
                      onSubmit={submit}
                      data-netlify="true"
                      data-netlify-honeypot="bot-field"
                >
                  <div className="row">
                    <div className="input-container first-name">
                      <label htmlFor={'firstName'}><Trans>first_name</Trans>*</label>
                      <input type="text"
                             name="firstName"
                             id={'firstName'}
                             value={formData.firstName}
                             onChange={e => setFormData({...formData, firstName: e.target.value})}
                             required={'required'}
                             ref={firstNameInputRef}
                      />
                    </div>
                    <div className="input-container last-name">
                      <label htmlFor={'lastName'}><Trans>last_name</Trans>*</label>
                      <input type="text"
                             name="lastName"
                             id={'lastName'}
                             value={formData.lastName}
                             required={'required'}
                             onChange={e => setFormData({...formData, lastName: e.target.value})}
                      />
                    </div>
                  </div>
                  <div className="input-container company-name">
                    <label htmlFor={'companyName'}><Trans>company_name</Trans>*</label>
                    <input type="text"
                           name="companyName"
                           id={'companyName'}
                           value={formData.companyName}
                           required={'required'}
                           onChange={e => setFormData({...formData, companyName: e.target.value})}
                    />
                  </div>

                  <div className="row">
                    <div className="input-container email">
                      <label htmlFor={'email'}><Trans>email</Trans>*</label>
                      <input type="email"
                             name="email"
                             id={'email'}
                             value={formData.email}
                             required={'required'}
                             onChange={e => setFormData({...formData, email: e.target.value})}
                      />
                    </div>
                    <div className="input-container phone-number">
                      <label htmlFor={'phoneNumber'}><Trans>phone_number</Trans> <span
                          style={{fontSize: 'smaller'}}>(<Trans>optional</Trans>)</span></label>
                      <input type="text"
                             name="phoneNumber"
                             value={formData.phoneNumber}
                             onChange={e => setFormData({...formData, phoneNumber: e.target.value})}
                      />
                    </div>
                  </div>

                  <div className="input-container country">
                    <label htmlFor={'countryList'}><Trans>country</Trans>*</label>
                    <select name="country"
                            id={'countryList'}
                            value={formData.country}
                            onChange={e => handleCountryChange({...formData, country: e.target.value})}
                            required>
                      <option value=""><Trans>please_select</Trans></option>
                      <option value="US">United States</option>
                      <option value="AD">Andorra</option>
                      <option value="AE">United Arab Emirates</option>
                      <option value="AF">Afghanistan</option>
                      <option value="AG">Antigua and Barbuda</option>
                      <option value="AI">Anguilla</option>
                      <option value="AL">Albania</option>
                      <option value="AM">Armenia</option>
                      <option value="AN">Netherlands Antilles</option>
                      <option value="AO">Angola</option>
                      <option value="AQ">Antarctica</option>
                      <option value="AR">Argentina</option>
                      <option value="AT">Austria</option>
                      <option value="AU">Australia</option>
                      <option value="AW">Aruba</option>
                      <option value="AX">Aland Islands</option>
                      <option value="AZ">Azerbaijan</option>
                      <option value="BA">Bosnia and Herzegovina</option>
                      <option value="BB">Barbados</option>
                      <option value="BD">Bangladesh</option>
                      <option value="BE">Belgium</option>
                      <option value="BF">Burkina Faso</option>
                      <option value="BG">Bulgaria</option>
                      <option value="BH">Bahrain</option>
                      <option value="BI">Burundi</option>
                      <option value="BJ">Benin</option>
                      <option value="BL">Saint Barthélemy</option>
                      <option value="BM">Bermuda</option>
                      <option value="BN">Brunei Darussalam</option>
                      <option value="BO">Bolivia</option>
                      <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                      <option value="BR">Brazil</option>
                      <option value="BS">Bahamas</option>
                      <option value="BT">Bhutan</option>
                      <option value="BV">Bouvet Island</option>
                      <option value="BW">Botswana</option>
                      <option value="BY">Belarus</option>
                      <option value="BZ">Belize</option>
                      <option value="CA">Canada</option>
                      <option value="CC">Cocos (Keeling) Islands</option>
                      <option value="CD">Congo, the Democratic Republic of the</option>
                      <option value="CF">Central African Republic</option>
                      <option value="CG">Congo</option>
                      <option value="CH">Switzerland</option>
                      <option value="CI">Cote d'Ivoire</option>
                      <option value="CK">Cook Islands</option>
                      <option value="CL">Chile</option>
                      <option value="CM">Cameroon</option>
                      <option value="CN">China</option>
                      <option value="CO">Colombia</option>
                      <option value="CR">Costa Rica</option>
                      <option value="CU">Cuba</option>
                      <option value="CV">Cape Verde</option>
                      <option value="CW">Curaçao</option>
                      <option value="CX">Christmas Island</option>
                      <option value="CY">Cyprus</option>
                      <option value="CZ">Czech Republic</option>
                      <option value="DE">Germany</option>
                      <option value="DJ">Djibouti</option>
                      <option value="DK">Denmark</option>
                      <option value="DM">Dominica</option>
                      <option value="DO">Dominican Republic</option>
                      <option value="DZ">Algeria</option>
                      <option value="EC">Ecuador</option>
                      <option value="EE">Estonia</option>
                      <option value="EG">Egypt</option>
                      <option value="EH">Western Sahara</option>
                      <option value="ER">Eritrea</option>
                      <option value="ES">Spain</option>
                      <option value="ET">Ethiopia</option>
                      <option value="FI">Finland</option>
                      <option value="FJ">Fiji</option>
                      <option value="FK">Falkland Islands</option>
                      <option value="FO">Faroe Islands</option>
                      <option value="FR">France</option>
                      <option value="GA">Gabon</option>
                      <option value="GB">United Kingdom</option>
                      <option value="GD">Grenada</option>
                      <option value="GE">Georgia</option>
                      <option value="GF">French Guiana</option>
                      <option value="GG">Guernsey</option>
                      <option value="GH">Ghana</option>
                      <option value="GI">Gibraltar</option>
                      <option value="GL">Greenland</option>
                      <option value="GM">Gambia</option>
                      <option value="GN">Guinea</option>
                      <option value="GP">Guadeloupe</option>
                      <option value="GQ">Equatorial Guinea</option>
                      <option value="GR">Greece</option>
                      <option value="GS">South Georgia and the South Sandwich Islands</option>
                      <option value="GT">Guatemala</option>
                      <option value="GW">Guinea-Bissau</option>
                      <option value="GY">Guyana</option>
                      <option value="HK">Hong Kong</option>
                      <option value="HM">Heard Island and McDonald Islands</option>
                      <option value="HN">Honduras</option>
                      <option value="HR">Croatia</option>
                      <option value="HT">Haiti</option>
                      <option value="HU">Hungary</option>
                      <option value="ID">Indonesia</option>
                      <option value="IE">Ireland</option>
                      <option value="IL">Israel</option>
                      <option value="IM">Isle of Man</option>
                      <option value="IN">India</option>
                      <option value="IO">British Indian Ocean Territory</option>
                      <option value="IQ">Iraq</option>
                      <option value="IR">Iran, Islamic Republic of</option>
                      <option value="IS">Iceland</option>
                      <option value="IT">Italy</option>
                      <option value="JE">Jersey</option>
                      <option value="JM">Jamaica</option>
                      <option value="JO">Jordan</option>
                      <option value="JP">Japan</option>
                      <option value="KE">Kenya</option>
                      <option value="KG">Kyrgyzstan</option>
                      <option value="KH">Cambodia</option>
                      <option value="KI">Kiribati</option>
                      <option value="KM">Comoros</option>
                      <option value="KN">Saint Kitts and Nevis</option>
                      <option value="KP">Korea-Democratic People's Republic of</option>
                      <option value="KR">Korea-Republic of</option>
                      <option value="KW">Kuwait</option>
                      <option value="KY">Cayman Islands</option>
                      <option value="KZ">Kazakhstan</option>
                      <option value="LA">Lao People's Democratic Republic</option>
                      <option value="LB">Lebanon</option>
                      <option value="LC">Saint Lucia</option>
                      <option value="LI">Liechtenstein</option>
                      <option value="LK">Sri Lanka</option>
                      <option value="LR">Liberia</option>
                      <option value="LS">Lesotho</option>
                      <option value="LT">Lithuania</option>
                      <option value="LU">Luxembourg</option>
                      <option value="LV">Latvia</option>
                      <option value="LY">Libyan Arab Jamahiriya</option>
                      <option value="MA">Morocco</option>
                      <option value="MC">Monaco</option>
                      <option value="MD">"Moldova, Republic of"</option>
                      <option value="ME">Montenegro</option>
                      <option value="MF">Saint Martin (French part)</option>
                      <option value="MG">Madagascar</option>
                      <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                      <option value="ML">Mali</option>
                      <option value="MM">Myanmar</option>
                      <option value="MN">Mongolia</option>
                      <option value="MO">Macao</option>
                      <option value="MQ">Martinique</option>
                      <option value="MR">Mauritania</option>
                      <option value="MS">Montserrat</option>
                      <option value="MT">Malta</option>
                      <option value="MU">Mauritius</option>
                      <option value="MV">Maldives</option>
                      <option value="MW">Malawi</option>
                      <option value="MX">Mexico</option>
                      <option value="MY">Malaysia</option>
                      <option value="MZ">Mozambique</option>
                      <option value="NA">Namibia</option>
                      <option value="NC">New Caledonia</option>
                      <option value="NE">Niger</option>
                      <option value="NF">Norfolk Island</option>
                      <option value="NG">Nigeria</option>
                      <option value="NI">Nicaragua</option>
                      <option value="NL">Netherlands</option>
                      <option value="NO">Norway</option>
                      <option value="NP">Nepal</option>
                      <option value="NR">Nauru</option>
                      <option value="NU">Niue</option>
                      <option value="NZ">New Zealand</option>
                      <option value="OM">Oman</option>
                      <option value="PA">Panama</option>
                      <option value="PE">Peru</option>
                      <option value="PF">French Polynesia</option>
                      <option value="PG">Papua New Guinea</option>
                      <option value="PH">Philippines</option>
                      <option value="PK">Pakistan</option>
                      <option value="PL">Poland</option>
                      <option value="PM">Saint Pierre and Miquelon</option>
                      <option value="PN">Pitcairn</option>
                      <option value="PS">"Palestinian Territory, Occupied"</option>
                      <option value="PT">Portugal</option>
                      <option value="PU">Puerto Rico</option>
                      <option value="PY">Paraguay</option>
                      <option value="QA">Qatar</option>
                      <option value="RE">Reunion</option>
                      <option value="RO">Romania</option>
                      <option value="RS">Serbia</option>
                      <option value="RU">Russian Federation</option>
                      <option value="RW">Rwanda</option>
                      <option value="SA">Saudi Arabia</option>
                      <option value="SB">Solomon Islands</option>
                      <option value="SC">Seychelles</option>
                      <option value="SD">Sudan</option>
                      <option value="SE">Sweden</option>
                      <option value="SG">Singapore</option>
                      <option value="SH">Saint Helena-Ascension-Tristan da Cunha</option>
                      <option value="SI">Slovenia</option>
                      <option value="SJ">Svalbard and Jan Mayen</option>
                      <option value="SK">Slovakia</option>
                      <option value="SL">Sierra Leone</option>
                      <option value="SM">San Marino</option>
                      <option value="SN">Senegal</option>
                      <option value="SO">Somalia</option>
                      <option value="SR">Suriname</option>
                      <option value="SS">South Sudan</option>
                      <option value="ST">Sao Tome and Principe</option>
                      <option value="SV">El Salvador</option>
                      <option value="SX">Sint Maarten (Dutch part)</option>
                      <option value="SY">Syrian Arab Republic</option>
                      <option value="SZ">Swaziland</option>
                      <option value="TC">Turks and Caicos Islands</option>
                      <option value="TD">Chad</option>
                      <option value="TF">French Southern Territories</option>
                      <option value="TG">Togo</option>
                      <option value="TH">Thailand</option>
                      <option value="TJ">Tajikistan</option>
                      <option value="TK">Tokelau</option>
                      <option value="TL">Timor-Leste</option>
                      <option value="TM">Turkmenistan</option>
                      <option value="TN">Tunisia</option>
                      <option value="TO">Tonga</option>
                      <option value="TR">Turkey</option>
                      <option value="TT">Trinidad and Tobago</option>
                      <option value="TV">Tuvalu</option>
                      <option value="TW">Chinese Taipei</option>
                      <option value="TZ">Tanzania, United Republic of</option>
                      <option value="UA">Ukraine</option>
                      <option value="UG">Uganda</option>
                      <option value="UY">Uruguay</option>
                      <option value="UZ">Uzbekistan</option>
                      <option value="VA">Holy See (Vatican City State)</option>
                      <option value="VC">Saint Vincent and the Grenadines</option>
                      <option value="VE">Venezuela, Bolivarian Republic of</option>
                      <option value="VG">British Virgin Islands</option>
                      <option value="VI">U.S. Virgin Islands</option>
                      <option value="VN">Vietnam</option>
                      <option value="VU">Vanuatu</option>
                      <option value="WF">Wallis and Futuna</option>
                      <option value="WS">Samoa</option>
                      <option value="YE">Yemen</option>
                      <option value="YT">Mayotte</option>
                      <option value="ZA">South Africa</option>
                      <option value="ZM">Zambia</option>
                      <option value="ZW">Zimbabwe</option>
                    </select>
                  </div>
                  {
                    isCountryUsa ?
                        <div className="input-container state">
                          <label htmlFor={'stateList'}><Trans>state</Trans>*</label>
                          <select name="state"
                                  id={'stateList'}
                                  value={formData.state}
                                  onChange={e => setFormData({...formData, state: e.target.value})}
                                  required>
                            <option value="">Please Select</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="PR">Puerto Rico</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                          </select>
                        </div>
                        : null
                  }

                  <div className="input-container message">
                    <label htmlFor="message"><Trans>message</Trans> <span
                        style={{fontSize: 'smaller'}}>(<Trans>optional</Trans>)</span></label>
                    <textarea name="message"
                              value={formData.message}
                              maxLength={250}
                              onChange={e => setFormData({...formData, message: e.target.value})}
                    />
                  </div>
                  <div className="input-container-submit">
                    <input
                        // onClick={gtag_report_conversion_asia_submit}
                        className={'form-submit'}
                        type="submit"
                        aria-disabled={!isFormValid ? 'true' : 'false'}
                        value={'Send'}
                    />
                  </div>

                </form>
              </div>
            </div>
          </section>
          <section className="global-offices">
            <div className="container offices">
              <h2>
                <Trans>global_offices</Trans>
              </h2>
              <div>
                <Link to={'/about/offices/#gotoAmericas'} role={'button'}>
                  <figure>
                    <StaticImage
                        src="../../images/about/digilock-americas.png"
                        width={342}
                        height={236}
                        formats={["auto", "webp", "avif"]}
                        alt="Digilock Americas, San Francisco"
                    />
                    <figcaption className={'center'}>
                      <Trans>Digilock Americas</Trans>
                    </figcaption>
                  </figure>
                </Link>
                <Link to={'/about/offices/#gotoEurope'} role={'button'}>
                  <figure>
                    <StaticImage
                        src="../../images/about/digilock-europe-netherlands.png"
                        width={342}
                        height={236}
                        formats={["auto", "webp", "avif"]}
                        alt="Digilock Netherlands"
                    />
                    <figcaption className={'center'}>
                      <Trans>Digilock Europe, The Netherlands</Trans>
                    </figcaption>
                  </figure>
                </Link>
                <Link to={'/about/offices/#gotoAsia'} role={'button'}>
                  <figure>
                    <StaticImage
                        src="../../images/about/digilock-asia.png"
                        width={342}
                        height={236}
                        formats={["auto", "webp", "avif"]}
                        alt="Digilock Asia"
                    />
                    <figcaption className={'center'}>
                      <Trans>Digilock Asia, Hong Kong</Trans>
                    </figcaption>
                  </figure>
                </Link>

              </div>
              <div>
                <div>
                  {/*<Link to={'/about/offices/#gotoEurope'} role={'button'}>*/}
                  {/*  <figure>*/}
                  {/*    <StaticImage*/}
                  {/*        src="../../images/about/digilock-europe-netherlands.png"*/}
                  {/*        width={342}*/}
                  {/*        height={236}*/}
                  {/*        formats={["auto", "webp", "avif"]}*/}
                  {/*        alt="Digilock Netherlands"*/}
                  {/*    />*/}
                  {/*    <figcaption className={'center'}>*/}
                  {/*      <Trans>Digilock Europe, The Netherlands</Trans>*/}
                  {/*    </figcaption>*/}
                  {/*  </figure>*/}
                  {/*</Link>*/}
                </div>
                <div>
                  {/*<Link to={'/about/offices/#gotoAsia'} role={'button'}>*/}
                  {/*  <figure>*/}
                  {/*    <StaticImage*/}
                  {/*        src="../../images/about/digilock-asia.png"*/}
                  {/*        width={342}*/}
                  {/*        height={236}*/}
                  {/*        formats={["auto", "webp", "avif"]}*/}
                  {/*        alt="Digilock Asia"*/}
                  {/*    />*/}
                  {/*    <figcaption className={'center'}>*/}
                  {/*      <Trans>Digilock Asia, Hong Kong</Trans>*/}
                  {/*    </figcaption>*/}
                  {/*  </figure>*/}
                  {/*</Link>*/}
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
