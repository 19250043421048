import React from 'react';
import PropTypes from "prop-types";
import {Trans} from "gatsby-plugin-react-i18next";
import callIcon from "../../images/shared/call-icon.svg";
import emailIcon from "../../images/shared/email-icon.svg";
import chatIcon from "../../images/shared/chat-icon.svg";

const ContactCards = (props) => {

    return (
        <>
            <div className={props.whichStep === 1 ? 'contact-card-container show' : 'contact-card-container'}>
                <div className={'contact-card-label'}>
                    <div>
                        <h4><Trans>product_support</Trans></h4>
                        <p><Trans>need_assistance_with</Trans></p>
                        <ul>
                            <li><Trans>warranty_support</Trans></li>
                            <li><Trans>troubleshooting</Trans></li>
                        </ul>
                    </div>
                    <div>
                        <a href="/support/#contactInfo" className="btn btn--orange">
                            <Trans>continue</Trans>
                        </a>
                    </div>

                </div>
                <div className={'contact-card-label'}>
                    <div>
                        <h4><Trans>purchase_locks</Trans></h4>
                        <p><Trans>interested_purchasing_locks</Trans></p>
                        <ul>
                            <li><Trans>product_selection</Trans></li>
                            <li><Trans>product_information</Trans></li>
                            <li><Trans>product_pricing</Trans></li>
                            <li><Trans>product_upgrades</Trans></li>
                        </ul>
                    </div>
                    <div>
                        <a href="javascript:null" onClick={props.handleStepClick} className="btn btn--orange">
                            <Trans>continue</Trans>
                        </a>
                    </div>

                </div>
                <div className={'contact-card-label'}>
                    <div>
                        <h4><Trans>other_questions</Trans></h4>
                        <p><Trans>interested_in</Trans></p>
                        <ul>
                            <li><Trans>media_inquiry</Trans></li>
                            <li><Trans>accounting_inquiry</Trans></li>
                            <li><Trans>other_inquiry</Trans></li>
                        </ul>
                    </div>
                    <div>
                        <a href={"mailto:marketing@digilock.com"} className="btn btn--orange">
                            <Trans>continue</Trans>
                        </a>
                    </div>

                </div>

            </div>
            <div className={props.whichStep === 2 && !props.emailClicked ? 'contact-card-container show' : 'contact-card-container'}>
                <div className={'contact-card'}>
                    <img src={callIcon} alt="email icon" width={'100'} height={'100'}/>
                    <div>
                        <h4><Trans>call</Trans></h4>
                        <p><a href={"tel:" + props.info.phone}>{props.info.phone}</a></p>
                    </div>
                    <a href={"tel:" + props.info.phone} className="btn btn--orange">
                        <Trans>contact_us</Trans>
                    </a>
                </div>
                <div className={'contact-card'}>
                    <img src={emailIcon} alt="email icon" width={'100'} height={'100'}/>
                    <div>
                        <h4><Trans>email</Trans></h4>
                        <p><Trans>email_product_expert</Trans></p>
                    </div>
                    <a onClick={props.handleEmail} href="javascript: null" className="btn btn--orange">
                        <Trans>contact_us</Trans>
                    </a>
                </div>
                <div className={'contact-card'}>
                    <img src={chatIcon} alt="chat icon" width={'100'} height={'100'}/>
                    <div>
                        <h4><Trans>chat</Trans></h4>
                        <p><Trans>chat_with_product_expert</Trans></p>
                    </div>
                    <a onClick={props.handleChatClick} href="javascript: null;" className="btn btn--orange">
                        <Trans>contact_us</Trans>
                    </a>
                </div>
            </div>

        </>
    )

}

ContactCards.propTypes = {
    info: PropTypes.object,
    handleEmail: PropTypes.func,
    handleChatClick: PropTypes.func,
    emailClicked: PropTypes.bool,
    handleStepClick: PropTypes.func,
    whichStep: PropTypes.number
};

export default ContactCards;